<template lang="html">
  <div class="container-xxl pt-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'Index' }">Панель</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Аккаунты
        </li>
      </ol>
    </nav>
    <div class="d-flex mb-3">
      <div class="">
        <h3>
          Аккаунты
          <small class="text-secondary">
            {{ $store.state.accountsCount }}
          </small>
        </h3>
      </div>
      <div class="ms-5 me-auto">
        <input
          v-model="search"
          @change="get_accounts()"
          class="form-control"
          type="text"
          placeholder="Поиск по имени и логину">
      </div>
      <div class="btn-group">
        <input
          v-model="sort"
          @change="get_accounts()"
          value="name"
          type="radio"
          class="btn-check"
          id="btn-check-5-outlined">
        <label class="btn btn-light" for="btn-check-5-outlined">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sort-alpha-down" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M10.082 5.629 9.664 7H8.598l1.789-5.332h1.234L13.402 7h-1.12l-.419-1.371h-1.781zm1.57-.785L11 2.687h-.047l-.652 2.157h1.351z"/>
            <path d="M12.96 14H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645V14zM4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293V2.5z"/>
          </svg>
        </label>
        <input
          v-model="sort"
          @change="get_accounts()"
          value="positive"
          type="radio"
          class="btn-check"
          id="btn-check-outlined">
        <label class="btn btn-light" for="btn-check-outlined">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-emoji-smile" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"/>
          </svg>
        </label>
        <input
          v-model="sort"
          @change="get_accounts()"
          value="negative"
          type="radio"
          class="btn-check"
          id="btn-check-3-outlined">
        <label class="btn btn-light" for="btn-check-3-outlined">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-emoji-frown" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.498 3.498 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.498 4.498 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"/>
          </svg>
        </label>
        <input
          v-model="sort"
          @change="get_accounts()"
          value="message"
          type="radio"
          class="btn-check"
          id="btn-check-4-outlined">
        <label class="btn btn-light" for="btn-check-4-outlined">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bar-chart" viewBox="0 0 16 16">
            <path d="M4 11H2v3h2v-3zm5-4H7v7h2V7zm5-5v12h-2V2h2zm-2-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1h-2zM6 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm-5 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3z"/>
          </svg>
        </label>
      </div>
    </div>

    <div class="row">
      <div class="col-2">
        <button
          class="btn d-block mb-3 w-100"
          :class="{
            'btn-secondary': chatId,
            'btn-primary': !chatId,
          }"
          @click="chatId = null; get_accounts()">
          Все
        </button>
        <button
          v-for="chat in $store.state.chats"
          :key="chat.id"
          class="btn d-block mb-3 w-100"
          :class="{
            'btn-secondary': chatId != chat.id,
            'btn-primary': chatId === chat.id,
          }"
          @click="chatId = chat.id; get_accounts()">
          {{ chat.name || chat.telegram_name || 'Чат' }}
        </button>
      </div>
      <div class="col-10">
        <AccountsTable :accounts="$store.state.accounts"/>

        <Pagination
          v-model="page"
          :records="$store.state.accountsCount"
          :per-page="100"
          :options="{
            theme: 'bootstrap3',
            texts: {
              count: 'Показано с {from} до {to} из {count} аккаунтов|{count} аккаунтов|Один аккаунт',
              first: 'Первая',
              last: 'Последняя',
            }
          }"
          @paginate="$socket.emit('get_accounts', {sort, page, chatId})"
        />

      </div>
    </div>

    <div
      class="modal fade"
      id="accountModal"
      tabindex="-1"
      aria-labelledby="titleAccountModal"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="titlePostModal">Аккаунт в телеграме</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            {{ $store.state.account }}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
moment.locale('ru')
import Pagination from 'v-pagination-3'
import AccountsTable from '../components/AccountsTable'

export default {
  name: 'Accounts',
  components: {
    Pagination,
    AccountsTable
  },
  data () {
    return {
      search: null,
      sort: 'name',
      page: 1,
      chatId: null
    }
  },
  methods: {
    get_accounts () {
      this.$socket.emit('get_accounts', {
        search: this.search,
        sort: this.sort,
        page: this.page,
        chatId: this.chatId
      })
    }
  },
  async mounted () {
    try {
      this.$socket.emit('get_data', ['chats'])
      this.get_accounts()
    } catch (e) {
      console.error(e)
      this.$toast.error(`${e.type}: ${e.message}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.card{
  .btn-group{
    visibility: hidden;
  }
  &:hover{
    .btn-group{
      visibility: visible;
    }
  }
}
</style>
